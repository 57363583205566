import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../src/App.css';
import LegalDocumentTemplate from '../components/LegalDocumentTemplate';
import { privacyPolicy } from '../documents/privacyPolicy';

import racebaseLogo from '../assets/images/general/racebaseLogo_symbol_white.png';
import racebaseText from '../assets/images/general/racebaseText_whiteText.png';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const logoRef = useRef(null)
  return (
    <div className="App">
      <header className="App-header">
        <nav className="nav-buttons">
          <button onClick={() => navigate(-1)} className="nav-button">← Go Back</button>
        </nav>
        <img ref={logoRef} src={racebaseLogo} className="App-logo" alt="RACEBASE_LOGO" />
        <h1>Privacy Policy</h1>
        <div className="arrow-down"></div>
      </header>

      <LegalDocumentTemplate content={privacyPolicy} />

      <div className="bottom-corner">
        <img src={racebaseText} alt="RACEBASE" className="bottom-corner-img" />
        <p className="bottom-corner-text">{new Date().getFullYear()}</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;