import React, { useRef, useState, useEffect } from 'react';
import { motion } from "framer-motion"; //useInView
import { useNavigate } from 'react-router-dom';
import "../css/Guide.css";

import racebaseLogo from '../assets/images/general/racebaseLogo_symbol_black.png';

const Guide = () => {

  const images = require.context("../assets/images/guide", false, /\.(png|jpe?g|svg)$/);
  const imageList = images.keys().reduce((acc, path) => {
    const key = path.replace("./", ""); // Remove './' from filename
    acc[key] = images(path);
    return acc;
  }, {});

  const navigate = useNavigate();
  const logoRef = useRef(null);
  const step0Ref = useRef(null);
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);
  const step4Ref = useRef(null);
  const step5Ref = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const sections = [
    {
      id: "step0",
      ref: step0Ref,
      title: "Nomenclature",
      text: `<strong>Public account:</strong> Users can see your data record in the data tab but need to request access to view it. \n\n <strong>Private account:</strong> Your data is hidden from everyone, but you cannot view anyone else’s data either.`,
      screenshots: []
    },
    {
      id: "step1",
      ref: step1Ref,
      title: "General",
      text: "Tools which you can use throughout the software.",
      screenshots: [
        {
          header: "Top Banner",
          text: "The top banner is fully interactive.",
          img: imageList["racebaseGuide_1.0.png"]
        },
        {
          header: "Session Details Banner",
          text: "Clicking this banner, when available, will open the top-level details for the current timing or data document.",
          img: imageList["racebaseGuide_1.1.png"]
        },
        {
          header: "Session Details",
          text: "Use this modal to input top level details for a timing document or a data document. \n\n It will be available by clicking the date/ location/ session banner at the top of document pages.",
          img: imageList["racebaseGuide_1.2.png"]
        },
        {
          header: "Account Settings ",
          text: `You can find all software-related information here, including options for sending feedback about the app. \n\n Your feedback helps improve and expand the software.`,
          img: imageList["racebaseGuide_6.0.png"]
        },
        {
          header: "Data Settings",
          text: `Here you will find: \n\n  - The units toggle to switch between imperial and metric \n\n - The Racebase guide`,
          img: imageList["racebaseGuide_6.1.png"]
        },
      ],
    },
    {
      id: "step2",
      ref: step2Ref,
      title: "Stopwatch",
      text: "The Stopwatch tab features a high-performance stopwatch that allows you to time multiple timers simultaneously within a single session.",
      screenshots: [
        {
          header: "Session Inactive",
          text: "The stopwatch component is composed of many different entities which are all clickable. \n\n At this stage the session is inactive, click on the right hand button in a timer to start the session.",
          img: imageList["racebaseGuide_2.0.png"]
        },
        {
          header: "Session Active",
          text: `Once you have started a timer, the sector button on the left will be activated as well as the timer overview next to the session time. \n\n The timer information will be displayed in the left hand box. \n\n Swipe the timer across to edit the timers initials or delete the timer.`,
          img: imageList["racebaseGuide_2.1.png"]
        },
        {
          header: "Timing Session Overview",
          text: `Selecting the session overview will allow you to compare timers whilst the session is active and toggle between times and individual timer/ session deltas.`,
          img: imageList["racebaseGuide_2.2.png"]
        },
      ],
    },
    {
      id: "step3",
      ref: step3Ref,
      title: "Data",
      text: `The data tab is where you navigate all data records. \n\n The data records which are shown will depend on if your account is <strong>public</strong> or <strong>private</strong>.`,
      screenshots: [
        {
          header: "Data Overview",
          text: ``,
          img: imageList["racebaseGuide_3.0.png"]
        },
        {
          header: "Data Document",
          text: `A new data document is generated by clicking the '+' icon at the top of the Load Data screen. Within this document, you can add attributes, which, when selected, reveal related sub-attributes corresponding to the top-level attribute.`,
          img: imageList["racebaseGuide_3.1.png"]
        },
        {
          header: "Adding and copying attributes",
          text: `You can add new attributes here, as well as copying attribute from any of your other data documents.`,
          img: imageList["racebaseGuide_3.2.png"]
        },
        {
          header: "Adding sub attributes and links",
          text: `Data documents in racebase allow you attach sub attributes which related to a larger attribute. You can also attach links to websites.`,
          img: imageList["racebaseGuide_3.3.png"]
        },
        {
          header: "Linking a timing document to a data document",
          text: `Linking a session to a data document updates the data document to reflect the sessions weather.`,
          img: imageList["racebaseGuide_3.4.png"]
        },
        {
          header: "Linking a specific timer",
          text: `You can link specific timers to data documents that you create. `,
          img: imageList["racebaseGuide_3.5.png"]
        },
      ],
    },
    {
      id: "step4",
      ref: step4Ref,
      title: "Calendar",
      text: "The calendar combines your events together per month, When a new document is created a new event is automatically created as well. \n\n If multiple timing documents are created on the same date, and they have the same location and session title, then the session number will increase for that session title and they will be grouped into the same event.",
      screenshots: [
        {
          header: "Calendar Overview",
          text: ``,
          img: imageList["racebaseGuide_4.0.png"]
        },
        {
          header: "Update Event: Event Details",
          text: `The event details are required in order for you to save an event.`,
          img: imageList["racebaseGuide_4.1.png"]
        },
        {
          header: "Update event: Start and End Dates",
          text: "Click in this dropdown box to edit the start and end dates for your event.",
          img: imageList["racebaseGuide_4.2.png"]
        },
        {
          header: "Update Event: Event Settings",
          text: `The event settings allow you to: \n\n <strong>- Lock/unlock data\n\n- Allow attendees to edit the event details</strong> `,
          img: imageList["racebaseGuide_4.3.png"]
        },
        {
          header: "Invite Users to Event",
          text: "You can invite any user to your event in order to share data and event links and information efficiently.",
          img: imageList["racebaseGuide_4.4.png"]
        },
      ],
    },
    {
      id: "step5",
      ref: step5Ref,
      title: "Profile",
      text: "Your profile is only visible to other user if your account is public.",
      screenshots: [
        {
          header: "Profile Modal",
          text: `You can access other users profile modals by clicking on their profile record in invitation lists or the profile list in the header.\n\nYou can also access your own profile modal by tapping on the <strong>Amateur, Pro or Team</strong> icon in the top right corner of any page.`,
          img: imageList["racebaseGuide_5.0.png"]
        },
        {
          header: "Profile data analysis",
          text: `After clicking the chevron down in the profile modal, the data overview will appear. The details in this section will allow you to quickly navigate to various areas within the software.`,
          img: imageList["racebaseGuide_5.1.png"]
        },
        {
          header: "Edit Details",
          text: `To edit you details, click on the profile title or the details box in the profile screen.`,
          img: imageList["racebaseGuide_5.2.png"]
        },
        {
          header: "People page",
          text: `This page is accessed by selecting the <strong>people icon</strong> next to the upgrade icon.\n\nHere you can follow other users and if they are already following you, then you will become 'teammates'.`,
          img: imageList["racebaseGuide_5.3.png"]
        },
        {
          header: "Other users profile modal",
          text: `After clicking on a user record, their profile modal will be shown. You can view their profile much like your own.`,
          img: imageList["racebaseGuide_5.4.png"]
        },
        {
          header: "Activity Tracker",
          text: `This page is available by selecting the <strong>bell icon</strong> in the header component next to your account type.\n\n You will be notified of any interactions you need to make with software here as well as your working history of interactions.`,
          img: imageList["racebaseGuide_5.5.png"]
        },
      ],
    },
  ];

  const [canGoBack, setCanGoBack] = useState(false);

  useEffect(() => {
    setCanGoBack(window.history.length > 2); // If history length is >2, the user can go back
  }, []);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300); // Show button after scrolling 300px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <nav className="nav-buttons">
        {canGoBack && (
          <button onClick={() => navigate(-1)} className="nav-button">← Go Back</button>
        )}
      </nav>
      <header className="guide-header">
        <img ref={logoRef} src={racebaseLogo} className="guide-logo" alt="RACEBASE_LOGO" />
        <h1>Guide</h1>
      </header>

      {/* Introduction Text */}
      <section className="guide-intro">
        <h2>Welcome to the Racebase guide.</h2>
        <p>Click on a page below to navigate to a section.</p>
      </section>

      {/* Table of Contents */}
      <nav className="toc">
        <h2 className="title">Contents</h2>
        <ul>
          {sections.map((section) => (
            <li key={section.id} onClick={() => scrollToSection(section.ref)}>
              {section.title}
            </li>
          ))}
        </ul>
      </nav>

      {/* Content Sections */}
      {sections.map((section) => (
        <section key={section.id} ref={section.ref} id={section.id}>
          <h2 className="title">{section.title}</h2>

          <p className="primaryText"
            dangerouslySetInnerHTML={{
              __html: section.text.replace(/\n\n/g, "<br><br>"),
            }}
          />

          {/* Map through each screenshot in the section */}
          <div className="guide-steps">
            {section.screenshots.map((screenshot, index) => (
              <GuideStep
                key={index}
                header={screenshot.header}
                text={screenshot.text}
                img={screenshot.img}
              />
            ))}
          </div>
        </section>
      ))}

      {/* Back to Top Button (Shows Only When Scrolled Down) */}
      {showButton && (
        <button className="back-to-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          ↑ Back to Top
        </button>
      )}
    </div>
  );
};

// Component for Animated Screenshot Section
const GuideStep = ({ header, text, img }) => {
  return (
    <motion.div
      className="guide-step"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      {/* Screenshot Header */}
      <h3>{header}</h3>
      {/* Screenshot Text */}
      <p
        dangerouslySetInnerHTML={{
          __html: text.replace(/\n\n/g, "<br><br>"),
        }}
      />
      {/* Screenshot Image */}
      <img src={img} alt="Step Screenshot" className="screenshot" />
    </motion.div>
  );
};


export default Guide;