//use next.js for the actual website.
//this is for the email confirmation only
//https://www.theracebase.com
//npm start
//http://localhost:3000
//this needs to be typescript...    npx create-react-app realm-web-react-quickstart --template=typescript or for next.js    npx create-next-app@latest
//'vercel --prod' (to upload production)     'vercel' (to upload a preview)

//https://apps.apple.com/gb/app/the-racebase/id6499471294

import React from 'react';
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import EmailConfirmation from './pages/EmailConfirmation';
import ResetPassword from './pages/ResetPassword';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Guide from './pages/Guide';
import QRCode from 'react-qr-code';

import racebaseLogo from './assets/images/general/racebaseLogo_symbol_white.png';
import racebaseText from './assets/images/general/racebaseText_whiteText.png';


function App() {

  const [isDesktop, setIsDesktop] = useState(false);
  const appStoreURL = "https://apps.apple.com/gb/app/the-racebase/id6499471294";

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);        // Add event listener
    return () => window.removeEventListener("resize", handleResize);    // Cleanup function to remove event listener on unmount
  }, []);

  const handleAppStoreClick = () => {
    window.open(appStoreURL, "_blank", "noopener,noreferrer");
  };
  
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <header className="App-header">
                <img src={racebaseLogo} className="App-logo" alt="RACEBASE_LOGO" />

                {/* Navigation Buttons */}
                <nav className="nav-buttons">
                  <Link to="/privacy-policy" className="nav-button">Privacy Policy</Link>
                  <Link to="/guide" className="nav-button">Guide</Link>
                </nav>
              </header>

              
              <div className="qr-corner">
                {isDesktop ? 
                  <div 
                    className="app-store-button"
                    onClick={handleAppStoreClick} 
                  >
                    <QRCode 
                      value={appStoreURL} 
                      size={100}
                      fgColor="#FFFFFF"  // QR code color (black in this case)
                      bgColor="transparent"
                    />
                  </div>
                :
                  <div 
                    className="app-store-button" 
                    onClick={handleAppStoreClick} 
                    style={{ cursor: 'pointer' }}
                  >
                    <img 
                      src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" 
                      alt="Download on the App Store" 
                      style={{ width: '150px' }} 
                    />
                  </div>
                }
              </div>

              <div className="bottom-corner">
                <p className="bottom-corner-text">©</p>
                <img src={racebaseText} alt="RACEBASE" className="bottom-corner-img" />
                <p className="bottom-corner-text">{new Date().getFullYear()}</p>
              </div>
            </div>
          }
        />

        {/* Route for email confirmation */}
        <Route exact path="/confirm-email" element={<EmailConfirmation/>} />
        
        {/* Route for password reset */}
        <Route exact path="/reset-password" element={<ResetPassword/>} />

        {/* Route for password reset */}
        <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} />

        {/* Route for the guide */}
        <Route exact path="/guide" element={<Guide />} />
      </Routes>
    </Router>
  );
}

export default App;